.app {
  background-color: #333333;
  font-family: Helvetica, sans-serif;
  color: whitesmoke;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  margin: 0 10%;
}

.header {
  font-size: 4em;
  margin: 0;
  text-decoration: underline;
}

.thingContainer {
  word-break: break-all;
}

.thingContainer > *+* {
  margin: 0 0 0 10px;
}

.thingContainer span, .thingContainer a {
  font-size: 2em;
}

@media only screen and (max-device-width:390px)  {
  .thingContainer span, .thingContainer a {
    font-size: 1.5em;
  }

  .header {
    font-size: 3em;
  }
}